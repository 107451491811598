import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'

import Head from '../components/head'
import Meta from '../components/meta'

import config from '../../config/site-config'
import formatHtmlTitle from '../utils/formatHtmlTitle'

const NotFoundPage = () => (
  <div className="fourOhFour">
    <Helmet>
      <html data-style="main" />
    </Helmet>
    <Meta />
    <Head title={formatHtmlTitle("404")} />
    <h2><span className="fourOhFourText">404</span> Page Not Found</h2>
    <p>D’oh! The page you're looking for couldn’t be found. Seems like something’s broken…</p>
    <p>You can head on over to the <Link to="/">homepage</Link> to try and find it again.</p>
    <p>If you still feel like something’s gone missing, please feel free to <a href={config.social.email.url}>send me an email</a> about it.</p>
  </div>
)

export default NotFoundPage
