import React from 'react'
import { Helmet } from 'react-helmet'

const Meta = () => (
	<Helmet>
		<link rel="icon" href="/favicon.ico" />
		<link rel="icon" type="image/png" sizes="16x16" href="/favicons/16x16.png" />
		<link rel="icon" type="image/png" sizes="32x32" href="/favicons/32x32.png" />
		<link rel="icon" type="image/png" sizes="48x48" href="/favicons/48x48.png" />
		<link rel="icon" type="image/png" sizes="64x64" href="/favicons/64x64.png" />
		<link rel="icon" type="image/png" sizes="192x192" href="/favicons/192x192.png" />
		<link rel="apple-touch-icon" sizes="120x120" href="/favicons/touch-120x120.png" />
		<link rel="apple-touch-icon-precomposed" sizes="152x152" href="/favicons/touch-152x152.png" />
		<link rel="apple-touch-icon-precomposed" sizes="167x167" href="/favicons/touch-167x167.png" />
		<link rel="apple-touch-icon-precomposed" sizes="180x180" href="/favicons/touch-180x180.png" />
		
		<meta name="theme-color" content="hsl(45, 25%, 92%)" media="(prefers-color-scheme: light)" />
		<meta name="theme-color" content="hsl(30, 10%, 11%)" media="(prefers-color-scheme: dark)" />
	</Helmet>
)

export default Meta
